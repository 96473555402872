import store from '@/store';

/* 词语计算 */
export function termsReckon(val) {
  const termsItem = store.state.dict;
  const termsDictory = termsItem.dictory;
  const businessManagement = termsItem.businessManagement;
  let item = null;
  if (termsDictory) {
    let arr = termsDictory[businessManagement].child;
    for (var i = 0; i < arr.length; i++) {
      if (arr[i].code == val) {
        item = arr[i];
        break;
      }
    }
  }
  return item;
}

/* 路由计算 */
export function detailsRoute(val) {
  let path = '';

  /* 用水报装 */
  if (val === '0001') {
    path = '/Expand/InstallWater';
    return path;
  }

  /* 过户更名 */
  if (val === '0021') {
    path = '/Business/WaterTransfer';
    return path;
  }

  /* 报事报修 */
  if (val === '0003') {
    path = '/Business/Repair';
    return path;
  }

  /* 阶梯人口 */
  if (val === '0004') {
    path = '/Business/Population';
    return path;
  }

  /* 低保申请 */
  if (val === '0005') {
    path = '/Business/Subsistence';
    return path;
  }

  /* 水表报停 */
  if (val === '0006') {
    path = '/Business/WaterStop';
    return path;
  }

  /* 水表复装 */
  if (val === '0007') {
    path = '/Business/WaterResume';
    return path;
  }

  /* 销户 */
  if (val === '0008') {
    path = '/Business/WaterClose';
    return path;
  }

  /* 移管申请 */
  if (val === '0009') {
    path = '/Business/WaterpipeMove';
    return path;
  }

  /* 水表迁移 */
  if (val === '0010') {
    path = '/Business/WaterMeterMove';
    return path;
  }
  /* 更名 */
  if (val === '0012') {
    path = '/Business/WaterRenamed';
    return path;
  }
  /* 用户信息变更 开票信息变更 */
  if (val === '0013' || val === '0017') {
    path = '/Business/WaterNewsTurn';
    return path;
  }
  /* 用水性质变更 */
  if (val === '0014') {
    path = '/Business/WaterNatureTurn';
    return path;
  }

  /* 违章举报 */
  if (val === '0101') {
    path = '/Feedback/ViolationReport';
    return path;
  }

  /* 意见反馈 */
  if (val === '0102') {
    path = '/Feedback/Proposal';
    return path;
  }

  /* 报事报修 */
  if (val === '0103') {
    path = '/Feedback/RepairApply';
    return path;
  }
}
